var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "line-date", class: _vm.data.class + " " + _vm.headerClass },
    [
      _c("div", {
        class: { showTime: !_vm.showTime },
        domProps: { textContent: _vm._s(_vm.data.txt) },
      }),
      _vm.showTime
        ? _c("arrival-time", {
            attrs: {
              startDate: _vm.data.date,
              arrivalDate: _vm.data.arrival_time || _vm.data.date,
            },
            on: {
              "set-class": function (mode) {
                return (_vm.headerClass = "mode-" + mode)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }